import React from 'react';
import { Container } from 'react-bootstrap';

const HousePrices = () => {
    return (
        <>
        <Container style={{ marginTop: '1rem' }}>
            <h1>This content has moved</h1>
            <hr />
            <p><a href="https://ottaproperty.co.uk">Click here</a> to access my work on house prices</p>

        </Container>        
        </>
    );
};

export default HousePrices;
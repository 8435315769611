import React, { useEffect } from 'react';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';

const TallyContent = () => (
  <iframe 
    data-tally-src="https://tally.so/embed/nrd77o?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1" 
    loading="lazy" 
    width="100%" 
    height="276" 
    frameBorder="0" 
    marginHeight="0" 
    marginWidth="0" 
    title="Contact form"
  ></iframe>
);

function About() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.Tally.loadEmbeds();
    };

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Container style={{ marginTop: '2rem' }}>
      <h2 className="mt-5 mb-4">About the Founder</h2>
      <p className="lead">
        I'm James Twallin, a data nerd who's passionate about renewable energy and innovative technologies. With a background in Geophysics, I've applied my skills to various aspects of the energy sector for over a decade, from energy exploration to cutting-edge clean energy solutions.
      </p>
      <p>
        My expertise spans across data competencies. I've played key roles in developing thermal storage units, optimising home energy ecosystems, and delivering multi-MW renewable energy projects. I'm particularly passionate about tackling the complex challenges of heat management in the context of clean energy.
      </p>
      <p>
      The projects listed below reflect my commitment to leveraging technology and data for practical, impactful solutions in the real estate and energy sectors. If you have a data challenge or an interesting project in mind, I'd love to hear from you. Let's chat! Contact me using the form below or email directly at hello[at]inorite.co.uk.
      </p>


      <h2 className="mt-5 mb-4">Projects</h2>
      <Row>
        <Col md={6} className="mb-4">
          <Card>
            <Card.Body>
              <Card.Title>Windfarm Yields Analysis</Card.Title>
              <Card.Text>
                A comprehensive study on the efficiency and output of various windfarms. This project includes data visualisation and statistical analysis to provide insights into renewable energy production.
              </Card.Text>
              <Card.Link href="https://windtable.co.uk" target="_blank" rel="noopener noreferrer">View Project</Card.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-4">
          <Card>
            <Card.Body>
              <Card.Title>Power of 10 Data Visualisation</Card.Title>
              <Card.Text>
                Interactive visualisations of athletic performance data from Power of 10. This project showcases season's best times for athletes across various events, excluding wind-assisted results.
              </Card.Text>
              <Card.Link href="#/athletics" target="_blank" rel="noopener noreferrer">View Visualisations</Card.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-4">
          <Card>
            <Card.Body>
              <Card.Title>UK Electricity Grid Analysis</Card.Title>
              <Card.Text>
                An in-depth exploration of the UK's electricity grid data. This includes visualisations and analysis of power generation, consumption patterns, and energy mix across the UK.
              </Card.Text>
              <Card.Link href="#/uk-electricity-grid" target="_blank" rel="noopener noreferrer">Explore UK Grid Data</Card.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-4">
          <Card>
            <Card.Body>
              <Card.Title>ottaproperty.co.uk</Card.Title>
              <Card.Text>
                ottaproperty.co.uk is the first machine learning enabled property index in the UK.
              </Card.Text>
              <Card.Link href="https://ottaproperty.co.uk" target="_blank" rel="noopener noreferrer">Visit ottaproperty.co.uk</Card.Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <h2 className="mt-5 mb-4">Blog Posts</h2>
      <ListGroup>
        <ListGroup.Item>
          <h6>Gas Leaks in RG8</h6>
          <small className="text-muted">Published on 2024-10-07</small>
          <p className="mb-1">An analysis of gas leak incidents in the RG8 postcode area.</p>
          <a href="#/gas-leaks-rg8" target="_blank" rel="noopener noreferrer">Read More</a>
        </ListGroup.Item>
        <ListGroup.Item>
          <h6>Wind Curtailment Analysis</h6>
          <small className="text-muted">Published on 2023-03-01</small>
          <p className="mb-1">An investigation into wind curtailment and its impact on renewable energy production.</p>
          <a href="#/wind" target="_blank" rel="noopener noreferrer">Read More</a>
        </ListGroup.Item>

      </ListGroup>

      <h2 className="mt-5 mb-4">External Writing</h2>
      <Card>
        <Card.Body>
          <Card.Title>Medium Blog</Card.Title>
          <Card.Text>
            We regularly write about data analysis, technology trends, and interesting findings from various datasets. 
            Check out our Medium page for a collection of articles on diverse topics that have caught our interest.
          </Card.Text>
          <Card.Link href="https://medium.com/@anotherperson" target="_blank" rel="noopener noreferrer">Visit Our Medium Page</Card.Link>
        </Card.Body>
      </Card>

      <Row className="mt-5 justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <Container className="text-center">
            <h2>Got a Data Challenge? Let's chat!</h2>
            <TallyContent />
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default About;